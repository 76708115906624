.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 16px;
}

.large i,
.large svg {
  font-size: 40px;
}

.small i,
.small svg {
  font-size: 24px;
}
