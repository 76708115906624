@import 'fonts.scss';

body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.01em;
}

a {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 !important;
}

p {
  margin: 0;
}

img {
  pointer-events: none;
}
