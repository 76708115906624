.wrapper {
  height: 100vh;

  .layout,
  .content {
    width: 100%;
    max-width: 2500px;
    background-color: #fff;
  }

  .layout-mobile {
    height: 100vh;
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    .layout {
      display: none !important;
    }

    .layout-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f2f7fa;
      padding: 10px;

      svg {
        width: 100%;
        height: 10%;
      }

      .content {
        background-color: transparent;
        padding-top: 30px;
        text-align: center;
        color: #708da8;
        font-size: 4vw;
      }
    }
  }
}
