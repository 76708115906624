.wrapper {
  height: 100vh;

  .layout {
    height: 100vh;

    .sider {
      background-color: #fff;
      box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.04), 6px 0px 18px rgba(0, 0, 0, 0.06);
    }

    .content {
      margin: 0 auto;
      max-width: 2500px;
      padding: 0 10px;
    }
  }
}

@media screen and (max-width: 768px) {}
