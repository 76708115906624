// Japanese

@font-face {
  font-family: 'Noto Sans';
  src: url('../static/fonts/NotoSansJP-Light.otf');
  src: local('NotoSans'), local('NotoSans'),
    url('../static/fonts/NotoSansJP-Light.otf') format('woff2'),
    url('../static/fonts/NotoSansJP-Light.otf') format('woff'),
    url('../static/fonts/NotoSansJP-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  unicode-range: U+3000-9FFF, U+ff;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../static/fonts/NotoSansJP-Regular.otf');
  src: local('NotoSans'), local('NotoSans'),
    url('../static/fonts/NotoSansJP-Regular.otf') format('woff2'),
    url('../static/fonts/NotoSansJP-Regular.otf') format('woff'),
    url('../static/fonts/NotoSansJP-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  unicode-range: U+3000-9FFF, U+ff;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../static/fonts/NotoSansJP-Medium.otf');
  src: local('NotoSans'), local('NotoSans'),
    url('../static/fonts/NotoSansJP-Medium.otf') format('woff2'),
    url('../static/fonts/NotoSansJP-Medium.otf') format('woff'),
    url('../static/fonts/NotoSansJP-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: bold;
  unicode-range: U+3000-9FFF, U+ff;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../static/fonts/NotoSansJP-Bold.otf');
  src: local('Noto Sans Bold'), local('NotoSans-Bold'),
    url('../static/fonts/NotoSansJP-Bold.otf') format('woff2'),
    url('../static/fonts/NotoSansJP-Bold.otf') format('woff'),
    url('../static/fonts/NotoSansJP-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: bold;
  unicode-range: U+3000-9FFF, U+ff;
}

// Latin

@font-face {
  font-family: 'Noto Sans';
  src: url('../static/fonts/NotoSansEN-Regular.otf');
  src: local('NotoSans'), local('NotoSans'),
    url('../static/fonts/NotoSansEN-Regular.woff') format('woff2'),
    url('../static/fonts/NotoSansEN-Regular.woff') format('woff'),
    url('../static/fonts/NotoSansEN-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../static/fonts/NotoSansEN-Medium.otf');
  src: local('NotoSans'), local('NotoSans'),
    url('../static/fonts/NotoSansEN-Medium.woff') format('woff2'),
    url('../static/fonts/NotoSansEN-Medium.woff') format('woff'),
    url('../static/fonts/NotoSansEN-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../static/fonts/NotoSansEN-Bold.otf');
  src: local('NotoSans'), local('NotoSans'),
    url('../static/fonts/NotoSansEN-Bold.woff') format('woff2'),
    url('../static/fonts/NotoSansEN-Bold.woff') format('woff'),
    url('../static/fonts/NotoSansEN-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: bold;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../static/fonts/NotoSansEN-Bold.otf');
  src: local('NotoSans'), local('NotoSans'),
    url('../static/fonts/NotoSansEN-Bold.woff') format('woff2'),
    url('../static/fonts/NotoSansEN-Bold.woff') format('woff'),
    url('../static/fonts/NotoSansEN-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: bold;
  unicode-range: U+000-5FF;
}
