.container {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .title {
    padding: 40px 0;
    color: #708da8;
    font-size: 28px;
  }

  .button {
    min-width: 230px;
    font-size: 18px;
    line-height: 20px;
  }
}
