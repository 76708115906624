.container-collapsed {
  :global(.ant-menu-submenu-arrow) {
    display: none;
  }

  .row-logo {
    cursor: pointer;
    width: 100%;
    padding: 20px 24px;
    border-bottom: 1px solid #ececec;
  }

  .row-profile-skeleton {
    padding: 24px 0 40px 24px;

    div:last-child {
      display: flex;
      flex-direction: column;
    }
  }

  .row-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 24px 0 40px 24px;

    .row-profile-content {
      position: absolute;
      max-width: 64%;
      overflow: hidden;
      left: 70px;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      line-height: 20px;

      h3 {
        color: #262a2f;
        font-weight: 800;
        font-size: 14px;
      }

      span {
        color: #afb6ba;
        font-size: 11px;
        font-weight: 500;
      }
    }
  }

  .row-profile-error {
    display: none;
  }

  .row-menu {
    width: calc(100% - 1px);
    height: calc(100vh - 65px - 109px - 79px);
    overflow-y: auto;
    overflow-x: hidden;

    ul {
      border: 0 !important;
    }

    svg {
      margin-right: 10px;
    }

    .menu-item {
      display: flex;
      align-items: center;

      &:hover {
        svg > g path {
          fill: #0766f3;
        }
        svg > path {
          fill: #0766f3;
        }
      }
    }

    .menu-item-submenu {
      margin: 0;

      a {
        line-height: 18px;
        white-space: normal;
        width: 174px;
      }
      a:hover {
        transition: 0.5s;
        color: #8cbce7 !important;
      }
    }

    .menu-item-submenu-active {
      border-right: 4px solid #8cbce7;

      a {
        font-weight: 800;
        color: #8cbce7 !important;
      }
    }

    .menu-item-settings {
      margin-top: 20px;
      padding-top: 20px;
      padding-bottom: 10px;
      border-top: 1px solid #ececec;
    }

    .menu-item-active {
      a {
        font-weight: 800;
        color: #0766f3;
      }

      svg > g path {
        fill: #0766f3;
      }

      svg > path {
        fill: #0766f3;
      }
    }

    .menu-subtitle {
      div {
        display: flex;
        align-items: center;
      }

      &:hover {
        svg > path {
          fill: #0766f3;
          stroke: #0766f3;
          transition: 0.5s;
        }

        span {
          color: #0766f3;
        }
      }
    }

    .menu-subtitle-content {
      color: #262a2f;
    }

    .menu-subtitle-active {
      .menu-subtitle-content {
        font-weight: 800;
      }

      svg > path {
        fill: #0766f3;
        stroke: #0766f3;
      }

      span {
        color: #0766f3;
      }
    }

    .skeleton-menu {
      padding-left: 25px;
      padding-bottom: 50px;
      height: 200px !important;
      margin-bottom: 20px;
    }

    .skeleton-hr {
      width: 100% !important;
      height: 0px !important;
      border-top: none !important;
      border-bottom: 1px solid #ececec;
    }

    .skeleton-footer {
      padding-left: 25px;
      padding-bottom: 50px;
      height: 20px !important;
      margin-top: 20px;
    }
  }

  .row-footer {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 24px;
    position: absolute;
    bottom: 0;

    &:hover {
      span {
        color: #0766f3;
        transition: 0.5s;
      }

      svg > path {
        fill: #0766f3;
        transition: 0.5s;
      }
    }

    span {
      cursor: pointer;
      padding-left: 10px;
      font-size: 11px;
      color: #c0c8cd;
    }
  }
}

.container {
  :global(.ant-menu-submenu-arrow) {
    display: none;
  }

  .row-logo {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 12px;
    border-bottom: 1px solid #ececec;
  }

  .row-profile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0 40px 0;
  }

  .row-menu {
    width: calc(100% - 1px);

    ul {
      border: 0 !important;
      width: 100%;

      li {
        display: flex;
        justify-content: center;

        a {
          display: flex;
        }

        div {
          padding-left: 25px;
        }
      }
    }

    .menu-item {
      display: flex;
      align-items: center;

      &:hover {
        svg > g path {
          fill: #0766f3;
        }
        svg > path {
          fill: #0766f3;
        }
      }
    }

    .menu-item-settings {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #ececec;
    }

    .menu-item-active {
      svg > g path {
        fill: #0766f3;
      }

      svg > path {
        fill: #0766f3;
      }
    }

    .menu-subtitle {
      div {
        display: flex;
        align-items: center;
      }

      &:hover {
        svg > path {
          fill: #0766f3;
          stroke: #0766f3;
          transition: 0.5s;
        }

        span {
          color: #0766f3;
        }
      }
    }

    .menu-subtitle-content {
      color: #262a2f;
    }

    .menu-subtitle-active {
      svg > path {
        fill: #0766f3;
        stroke: #0766f3;
      }

      span {
        color: #0766f3;
      }
    }
  }

  .row-footer {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px;
    position: absolute;
    bottom: 0;
    cursor: pointer;

    &:hover {
      span {
        color: #0766f3;
        transition: 0.5s;
      }

      svg > path {
        fill: #0766f3;
        transition: 0.5s;
      }
    }

    svg {
      width: 100%;
    }

    span {
      cursor: pointer;
      font-size: 11px;
      color: #c0c8cd;
    }
  }
}

.tooltip {
  h3 {
    color: #262a2f;
    font-weight: 800;
    font-size: 14px;
  }

  span {
    color: #afb6ba;
    font-size: 11px;
    font-weight: 500;
  }
}
::-webkit-scrollbar {
  display: block;
  width: 6px;
  background: transparent;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #e3ebf2;
}
